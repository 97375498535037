import { FLESIP_ID } from '@/utils/const'

export enum ESolutionManagment {
    proccess_managment = 4,
}

export enum EProductManagment {
    flesip = FLESIP_ID,
}

export enum EPackageManagement {
    flesip = 10,
}

import { useRouter } from 'next/router'

import axios from '@/lib/axios'
import useSWR from 'swr'

import { Opportunity } from '@/interfaces'
import { FiltersContext, FiltersState } from '@/context/filters'
import React, { useContext } from 'react'
import { statusArray } from './statusOpportunity'

interface OpportunityProps {
    id: string | number
}

interface OpportunitiesProps {
    pageIndex?: number
}

interface NewOpportunityProps {
    name: string
    user_id: number
    segment: number
    distributor_id: number
    contact_name: string
    contact_hours: string
    phone: string
    email: string
    init_date: string
    end_date: string
    amount?: string
    licenses?: string

    parent_id?: number
    opportunity_version?: string
}

interface SetStatusOpportunity {
    opportunity_id: number
    status:
        | string
        | 'initial'
        | 'pending'
        | 'proccess'
        | 'qualified'
        | 'qualified_error'
        | 'qualified_pending'
        | 'repre_vol'
        | 'repre_vol_error'
        | 'bonus_managment_pending'
        | 'bonus_managment_pending_sent'
        | 'bonus_managment_sent'
        | 'approved'
        | 'correct'
        | 'denied'
        | 'closed'
        | 'lost'
}

interface GetEvents {
    opportunity_id: number
}

interface CreateNote {
    data: {
        opportunity_id: number
        title: string
        note: string
    }
}

interface DeleteNote {
    opportunity_note_id: number
}

interface CreateExternalOpportunity {
    data: {
        name: string
        segment: number
        contact_name?: string
        phone: string
        email: string
        init_date?: Date
        end_date?: Date
        amount: string
        contact_hours?: string
    }
}

export const useOpportunity = ({ id }: OpportunityProps) => {
    const [isUpdating, setIsUpdating] = React.useState(false)

    const { data, error, mutate } = useSWR<Opportunity>(
        `/api/opportunity/${id}`,
        url => {
            if (!id) return null
            return axios
                .get(url)
                .then(res => res.data.data)
                .catch(error => {})
        },
    )

    const updateOpportunity = async ({ opportunity_id, data }) => {
        setIsUpdating(true)

        return axios({
            method: 'PUT',
            url: `/api/opportunity/${opportunity_id}`,
            data,
        })
            .then(res => {
                mutate()
                return res.data
            })
            .catch(error => {
                throw new Error(
                    '¡Lo sentimos, ha ocurrido un error inesperado!',
                )
            })
            .finally(() => {
                setIsUpdating(false)
            })
    }

    const setStatusOpportunity = async ({
        opportunity_id,
        status,
    }: SetStatusOpportunity) => {
        return axios({
            method: 'POST',
            url: `/api/opportunity/status`,
            data: { opportunity_id, status },
        })
            .then(res => {
                mutate()
                return res.data
            })
            .catch(error => {
                throw new Error(
                    '¡Lo sentimos, ha ocurrido un error inesperado!',
                )
            })
    }

    const createNote = async ({ data }: CreateNote) => {
        return axios({
            method: 'POST',
            url: `/api/opportunity/set-note`,
            data,
        })
            .then(res => {
                mutate()
                return res.data
            })
            .catch(error => {
                throw new Error(
                    '¡Lo sentimos, ha ocurrido un error inesperado!',
                )
            })
    }

    const deleteNote = async ({ opportunity_note_id }: DeleteNote) => {
        return axios({
            method: 'DELETE',
            url: `/api/opportunity/delete-note/${opportunity_note_id}`,
            data,
        })
            .then(res => {
                mutate()
                return res.data
            })
            .catch(error => {
                throw new Error(
                    '¡Lo sentimos, ha ocurrido un error inesperado!',
                )
            })
    }

    return {
        opportunity: data,
        isLoading: !data && !error,
        error,
        updateOpportunity,
        setStatusOpportunity,
        createNote,
        deleteNote,
        isUpdating,
    }
}

export const useOpportunities = ({ pageIndex = 1 }: OpportunitiesProps) => {
    const router = useRouter()
    const { filters } = useContext(FiltersContext)
    let params = {
        query_type: 'paginate',
        paginate: '10',
    }

    // @ts-ignore
    if (filters.search) params.search = filters.search
    // @ts-ignore
    if (filters.status) params.status = filters.status
    // @ts-ignore
    if (filters.end_date) params.end_date = filters.end_date
    // @ts-ignore
    if (filters.start_date) params.start_date = filters.start_date
    // @ts-ignore
    if (filters.commercial_id) params.commercial_id = filters.commercial_id
    // @ts-ignore
    if (filters.distributor_id) params.distributor_id = filters.distributor_id
    if (filters.opportunity_type)
        // @ts-ignore
        params.opportunity_type = filters.opportunity_type

    const { data, error, mutate } = useSWR(
        `/api/opportunity?page=${pageIndex}`,
        url =>
            axios({
                method: 'GET',
                url,
                params,
            })
                .then(res => res.data)
                .catch(error => {
                    throw new Error(error.response.data.message)
                }),
    )

    const createOpportunity = async ({
        name,
        user_id,
        segment,
        distributor_id,
        contact_name,
        contact_hours,
        phone,
        email,
        init_date,
        end_date,
        amount,
        licenses,
        parent_id,
        opportunity_version,
    }: NewOpportunityProps) => {
        return axios({
            method: 'POST',
            url: '/api/opportunity',
            data: {
                name,
                user_id,
                segment,
                distributor_id,
                contact_name,
                contact_hours,
                phone,
                email,
                init_date,
                end_date,
                amount,
                licenses,
                parent_id,
                opportunity_version,
            },
        })
            .then(res => {
                mutate()
                return res.data
            })
            .catch(error => {
                throw new Error(error.response.data.message)
            })
    }

    const deleteOpportunity = async ({ opportunity_id }) => {
        return axios({
            method: 'DELETE',
            url: `/api/opportunity/${opportunity_id}`,
        })
            .then(res => {
                mutate()
                return res.data
            })
            .catch(error => {
                if (error.response.status === 400) {
                    throw new Error(error.response.data.message)
                }

                throw new Error(
                    '¡Lo sentimos, ha ocurrido un error inesperado!',
                )
            })
    }

    const getDistributors = () => {
        return axios({
            method: 'GET',
            url: '/api/distributor',
        })
            .then(res => res.data.data)
            .catch(error => {
                throw new Error(error.response.data.message)
            })
    }

    const viewFile = async ({ file_id }) => {
        return axios({
            method: 'GET',
            url: `/api/opportunity/status/download/${file_id}`,
        })
            .then(res => {
                return res.data
            })
            .catch(error => {
                throw new Error(
                    '¡Lo sentimos, ha ocurrido un error inesperado!',
                )
            })
    }

    const uploadFile = async ({ opportunity_id, documents }) => {
        return axios({
            method: 'POST',
            url: `/api/opportunity/status/upload`,
            data: {
                opportunity_id,
                documents,
            },
        })
            .then(res => {
                mutate()
                return res.data
            })
            .catch(error => {
                throw new Error(
                    '¡Lo sentimos, ha ocurrido un error inesperado!',
                )
            })
    }

    return {
        opportunities: data,
        isLoading: !data && !error,
        error,
        createOpportunity,
        deleteOpportunity,
        getDistributors,
        viewFile,
        uploadFile,
    }
}

export const useGetEvents = ({ opportunity_id }: GetEvents) => {
    const { data, error, mutate } = useSWR(
        `/api/opportunity/${opportunity_id}/events`,
        url => {
            if (!opportunity_id) return []

            return axios({
                method: 'GET',
                url,
            })
                .then(res => res.data.data)
                .catch(error => {
                    throw new Error(error.response.data.message)
                })
        },
    )

    const getCommunications = ({ opportunity_id }: GetEvents) => {
        return axios({
            method: 'GET',
            url: `/api/opportunity/${opportunity_id}/events`,
            params: {
                event_type: 'sending',
            },
        })
            .then(res => {
                mutate()
                return res.data.data
            })
            .catch(error => {
                throw new Error(error.response.data.message)
            })
    }

    return {
        events: data,
        isLoading: !data && !error,
        error,
        getCommunications,
    }
}

export const getALLOpportunities = ({ filters }) => {
    let params = {
        query_type: 'all',
    }

    // @ts-ignore
    if (filters.search) params.search = filters.search
    // @ts-ignore
    if (filters.status) params.status = filters.status
    // @ts-ignore
    if (filters.end_date) params.end_date = filters.end_date
    // @ts-ignore
    if (filters.start_date) params.start_date = filters.start_date
    // @ts-ignore
    if (filters.commercial_id) params.commercial_id = filters.commercial_id
    // @ts-ignore
    if (filters.distributor_id) params.distributor_id = filters.distributor_id

    return axios({
        method: 'GET',
        url: '/api/opportunity',
        params,
    })
        .then(res => {
            return res.data.data.map(item => {
                return {
                    ID: item.id_str,
                    'Nombre de la oportunidad': item.name,
                    'Nombre del contacto': item.contact_name,
                    Estado:
                        statusArray.find(
                            element => element.status === item.status,
                        )?.text || '',
                    Responsable: item.comercial?.name || 'Sin definir',
                    Distribuidor: item.distributor?.name || 'Sin definir',
                    'Fecha prevista de cierre': item.prev_close_date
                        ? new Date(item.prev_close_date).toLocaleString(
                              'es-ES',
                              {
                                  day: 'numeric',
                                  month: 'numeric',
                                  year: 'numeric',
                                  hour12: false,
                              },
                          )
                        : '',
                    Teléfono: item.phone,
                    Correo: item.email,
                    'Hora de contácto': item.contact_hours,
                }
            })
        })
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export const useGetStatus = () => {
    const { data, error, mutate } = useSWR(`/api/opportunity/status`, url =>
        axios({
            method: 'GET',
            url,
        })
            .then(res => res.data.data)
            .catch(error => {
                throw new Error(
                    '¡Lo sentimos, ha ocurrido un error inesperado!',
                )
            }),
    )

    return {
        status: data,
        isLoading: !data && !error,
        error,
    }
}

export const generateURLClient = async ({ opportunity_id }) => {
    return axios({
        method: 'POST',
        url: `/api/opportunity/create-url-landing/${opportunity_id}`,
    })
        .then(res => res.data.data)
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export const sendURLClient = async ({ opportunity_id }) => {
    return axios({
        method: 'POST',
        url: `/api/opportunity/send-url-landing/${opportunity_id}`,
    })
        .then(res => res.data.data)
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export const sendShortURL = async ({ opportunity_id }) => {
    return axios({
        method: 'POST',
        url: `/api/opportunity/send-short-url/${opportunity_id}`,
    })
        .then(res => res.data.data)
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export const getOpportunityByUUID = async ({ opportunity_uuid }) => {
    return axios({
        method: 'GET',
        url: `/api/data-landing/${opportunity_uuid}`,
    })
        .then(res => res.data.data)
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export const updateOpportunityByUUID = async ({ opportunity_uuid, data }) => {
    return axios({
        method: 'PUT',
        url: `/api/data-landing/${opportunity_uuid}`,
        data: {
            data_landing: JSON.stringify(data),
        },
    })
        .then(res => res.data.data)
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export const getExternalOpportunity = async ({ id }: { id: number }) => {
    return axios({
        method: 'GET',
        url: `/api/ext/opportunity/${id}`,
    })
        .then(res => res.data?.data)
        .catch(error => {
            throw new Error(
                error?.response?.data?.message ??
                    '¡Lo sentimos, ha ocurrido un error inesperado!',
            )
        })
}

export const createExternalOpportunity = async ({
    data,
}: CreateExternalOpportunity) => {
    return axios({
        method: 'POST',
        url: `/api/ext/opportunity/0`,
        data,
    })
        .then(res => res.data)
        .catch(error => {
            throw new Error(
                error?.response?.data?.message ??
                    '¡Lo sentimos, ha ocurrido un error inesperado!',
            )
        })
}

export const updateExternalOpportunity = async ({ opportunity_id, data }) => {
    return axios({
        method: 'PUT',
        url: `/api/ext/opportunity/${opportunity_id}`,
        data,
    })
        .then(res => {
            return res.data
        })
        .catch(error => {
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

export const duplicateDocumentsOpportunity = async ({
    opportunity_id,
    new_opportunity_id,
}) => {
    return axios({
        method: 'POST',
        url: `/api/opportunity/status/duplicate-documents/${opportunity_id}/${new_opportunity_id}`,
    })
        .then(res => {
            return res.data
        })
        .catch(error => {
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

import { colors } from '@/utils/const'

export const statusArray = [
    {
        status: 'initial',
        text: 'Inicial',
        modalTitle: 'Cualificar la oportunidad',
        modalText: 'En proceso',
        color: colors.primary,
    },
    {
        status: 'pending',
        text: 'Pendiente',
        modalTitle: 'Cualificar la oportunidad',
        modalText: 'En proceso',
        color: colors.tertiary,
    },
    {
        status: 'proccess',
        text: 'En proceso',
        modalTitle: 'Cualificar la oportunidad',
        modalText: 'Cualificada',
        color: colors.primary,
    },
    {
        status: 'qualified',
        text: 'Cualificada',
        modalTitle: 'Representación voluntaria',
        modalText: 'Representación voluntaria',
        color: colors.primary,
    },
    {
        status: 'qualified_error',
        text: 'Error al Cualificar',
        modalTitle: 'Cualificar la oportunidad',
        modalText: 'Cualificada',
        color: colors['warning-high'],
    },
    {
        status: 'qualified_pending',
        text: 'Pendiente Cualificar',
        modalTitle: 'Cualificar la oportunidad',
        modalText: 'Cualificada',
        color: colors['warning-high'],
    },
    {
        status: 'repre_vol',
        text: 'Representación voluntaria',
        modalTitle: 'Representación voluntaria',
        modalText: 'Representación voluntaria',
        color: colors.primary,
    },
    {
        status: 'repre_vol_error',
        text: 'Error en Repre. Voluntario',
        modalTitle: 'Representación voluntaria',
        modalText: 'Representación voluntaria',
        color: colors['warning-high'],
    },
    {
        status: 'bonus_managment_pending',
        text: 'Gestión de bono pendiente',
        modalTitle: 'Gestión de bono',
        modalText: 'Gestión de bono',
        color: colors['warning-high'],
    },
    {
        status: 'bonus_managment_pending_antiquity',
        text: 'Gestión de bono pendiente-Antigüedad',
        modalTitle: 'Gestión de bono',
        modalText: 'Gestión de bono',
        color: colors['warning-high'],
    },
    {
        status: 'bonus_managment_pending_sent',
        text: 'Gestión de bono enviado pendiente',
        modalTitle: 'Gestión de bono',
        modalText: 'Gestión de bono',
        color: colors['warning-high'],
    },
    {
        status: 'bonus_managment_sent',
        text: 'Gestión de bono enviado',
        modalTitle: 'Gestión de bono',
        modalText: 'Gestión de bono',
        color: colors['success-high'],
    },
    {
        status: 'approved',
        text: 'Aprobado',
        modalTitle: 'Aprobado',
        modalText: 'Aprobado',
        color: colors['success-high'],
    },
    {
        status: 'correct',
        text: 'Subsanar',
        modalTitle: 'Subsanar',
        modalText: 'Subsanar',
        color: colors.primary,
    },
    {
        status: 'denied',
        text: 'No Admite Presentación',
        modalTitle: 'No Admite Presentación',
        modalText: 'No Admite Presentación',
        color: colors['danger-high'],
    },
    {
        status: 'closed',
        text: 'Cerrada',
        modalTitle: 'Cerrar oportunidad',
        modalText: 'Cerrar oportunidad',
        color: colors['success-high'],
    },
    {
        status: 'lost',
        text: 'Perdida',
        modalTitle: 'Perdida',
        modalText: 'Perdida',
        color: colors['danger-high'],
    },
    {
        status: 're_requested',
        text: 'Resolicitada',
        modalTitle: 'Resolicitada',
        modalText: 'Resolicitada',
        color: colors['danger-high'],
    },
    {
        status: 'desisted',
        text: 'Desistida',
        modalTitle: 'Desistida',
        modalText: 'Desistida',
        color: colors.primary,
    },
]

export const useStatusOpportuniy = () => {
    const getModalTitle = (status = '') => {
        if (status === '') return ''
        return (
            statusArray.find(item => item.status === status)?.modalTitle ?? ''
        )
    }

    const getStatusTextModal = (status = '') => {
        if (status === '') return ''
        return statusArray.find(item => item.status === status)?.modalText ?? ''
    }

    const getText = (status = '') => {
        if (status === '') return ''
        return statusArray.find(item => item.status === status)?.text ?? ''
    }

    return {
        getModalTitle,
        getStatusTextModal,
        getText,
    }
}

import axios from '@/lib/axios'
import useSWR from 'swr'

import { Client } from '@/interfaces'
import { useContext } from 'react'
import { ClientsFiltersContext } from '@/context/clientsFilters'

interface ClientsProps {
    pageIndex?: number
}

interface CustomerData {
    customerData: NewClientProps
}

interface NewClientProps {
    opportunity_id: number
    name: string
    contact_name?: string
    phone?: string
    email?: string
    dni?: string
    home_address?: string
    postal_code?: string
    province?: string
    town?: string
    contact_dni?: string
    contact_position?: string
    employees_number: number
    activity: string
    cnae?: string
    iae?: string
    antiquity?: string
    preliminary_agreement: boolean
    client_type?: string
    release_date: string
    sale_manager_id: number
    representative_volunteer_id?: number
    enterprise_created_date?: string
    representatives?: any
    products?: any
    has_printer?: boolean
    question_profile_id?: number
    question_test?: any
    collaborators?: any
}

interface ClientProps {
    client_id: number
    isExternal?: boolean
    options?: any
}

export const useClient = ({
    client_id,
    isExternal = false,
    options,
}: ClientProps) => {
    const { data, error, mutate } = useSWR<Client>(
        isExternal
            ? `/api/ext/client/${client_id}`
            : `/api/client/${client_id}`,
        url => {
            if (!client_id) return null

            return axios
                .get(url)
                .then(res => res.data.data)
                .catch(error => {})
        },
        options,
    )

    const updateClient = async ({ client_id, data }) => {
        if (!client_id)
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')

        return axios({
            method: 'PUT',
            url: `/api/client/${client_id}`,
            data,
        })
            .then(res => {
                mutate()
                return res.data
            })
            .catch(error => {
                if (
                    error.response.data.message ===
                    'The email has already been taken.'
                ) {
                    throw new Error('El correo ya esta en uso')
                }
                throw new Error(
                    '¡Lo sentimos, ha ocurrido un error inesperado!',
                )
            })
    }

    const sendDocuments = async ({ client_id }) => {
        return axios({
            method: 'GET',
            url: `/api/client/send-documents/${client_id}`,
        })
            .then(res => {
                mutate()
            })
            .catch(error => {
                throw new Error(
                    '¡Lo sentimos, ha ocurrido un error inesperado!',
                )
            })
    }

    return {
        client: data,
        isLoading: !data && !error,
        error,
        updateClient,
        sendDocuments,
    }
}

export const useClients = ({ pageIndex = 1 }: ClientsProps) => {
    const { clientsFilters } = useContext(ClientsFiltersContext)
    let params = {
        query_type: 'paginate',
        paginate: '10',
    }

    // @ts-ignore
    if (clientsFilters.search) params.search = clientsFilters.search

    const { data, error, mutate } = useSWR(
        `/api/client?page=${pageIndex}`,
        url =>
            axios({
                url,
                method: 'GET',
                params,
            })
                .then(res => res.data)
                .catch(error => {}),
    )

    const createClient = async ({ customerData }: CustomerData) => {
        return axios({
            method: 'POST',
            url: '/api/client',
            data: { ...customerData },
        })
            .then(res => {
                mutate()
                return res.data
            })
            .catch(error => {
                if (
                    error.response.data.message ===
                    'The email has already been taken.'
                ) {
                    throw new Error('El correo ya esta en uso')
                }
                throw new Error(error.response.data.message)
            })
    }

    const deleteClient = async ({ client_id }) => {
        return axios({
            method: 'DELETE',
            url: `/api/client/${client_id}`,
        })
            .then(res => {
                mutate()
                return res.data
            })
            .catch(error => {
                if (error.response.status === 400) {
                    throw new Error(error.response.data.message)
                }

                throw new Error(
                    '¡Lo sentimos, ha ocurrido un error inesperado!',
                )
            })
    }

    return {
        clients: data,
        isLoading: !data && !error,
        error,
        createClient,
        deleteClient,
    }
}

export const verifyEmail = async ({ email, exclude_client_id = undefined }) => {
    const data = !exclude_client_id ? { email } : { email, exclude_client_id }
    return axios({
        method: 'POST',
        url: `/api/client/verify-email`,
        data,
    })
        .then(res => {
            if (res.data.data.available === false) {
                throw new Error('El correo ya esta en uso')
            }
        })
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export const verifyDNI = async ({ dni, exclude_client_id = undefined }) => {
    const data = !exclude_client_id ? { dni } : { dni, exclude_client_id }
    return axios({
        method: 'POST',
        url: `/api/client/verify-dni`,
        data,
    })
        .then(res => {
            if (res.data.data.available === false) {
                throw new Error('Ya existe un cliente con estos datos', {
                    cause: res.data.data.opportunity_id,
                })
            }
        })
        .catch(error => {
            throw new Error(error.message, { cause: error.cause })
        })
}

export const getProducts = () => {
    return axios({
        method: 'GET',
        url: '/api/client/products',
    })
        .then(res => res.data)
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export const getVolunteerRepresentatives = () => {
    return axios({
        method: 'GET',
        url: '/api/repre-vol',
    })
        .then(res => res.data.data)
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export const cancelDocumentViafirma = async ({
    client_id,
}: {
    client_id: number
}) => {
    return axios({
        method: 'POST',
        url: `/api/client/cancel-document/${client_id}`,
    })
        .then(res => {
            return res.data
        })
        .catch(err => {
            throw new Error(err.response.data.message)
        })
}

export const refreshViafirmaDocuments = async ({ client_id }) => {
    return axios({
        url: `/api/client/download-document/${client_id}`,
        method: 'POST',
    })
        .then(res => {})
        .catch(error => {
            throw new Error(error.response?.data?.message)
        })
}

export const getQuestions = async () => {
    return axios({
        method: 'GET',
        url: `/api/questions`,
    })
        .then(res => {
            return res.data.data
        })
        .catch(error => {
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

export const getProfilesQuestions = async () => {
    return axios({
        method: 'GET',
        url: `/api/question-profiles`,
    })
        .then(res => {
            return res.data.data
        })
        .catch(error => {
            return []
        })
}

export const redirectEmail = async ({ client_id }) => {
    return axios({
        method: 'POST',
        url: `/api/client/redirect-email/${client_id}`,
    })
        .then(res => {
            return res.data.data
        })
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export const getRepresentativeTypes = async () => {
    return axios({
        method: 'GET',
        url: `/api/representative-types`,
    })
        .then(res => {
            return res.data
        })
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export const createExternalClient = async ({ customerData }: CustomerData) => {
    return axios({
        method: 'POST',
        url: `/api/ext/client/0`,
        data: customerData,
    })
        .then(res => res.data)
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export const verifyExternalDNI = async ({ dni }: { dni: string }) => {
    return axios({
        method: 'POST',
        url: `/api/ext/client/verify-dni`,
        data: {
            dni,
        },
    })
        .then(res => {
            if (res.data.data.available === false) {
                throw new Error('Ya existe un cliente con estos datos', {
                    cause: res.data.data.opportunity_id,
                })
            }
        })
        .catch(error => {
            throw new Error(error.message, { cause: error.cause })
        })
}

export const getProfilesQuestionsExternal = async () => {
    return axios({
        method: 'GET',
        url: `/api/ext/question-profiles`,
    })
        .then(res => {
            return res.data.data
        })
        .catch(error => {
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

export const sendDocumentsExternal = async ({
    client_id,
}: {
    client_id: number
}) => {
    return axios({
        method: 'GET',
        url: `/api/ext/send-documents/${client_id}`,
    })
        .then()
        .catch(error => {
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

export const getClientExternal = async ({
    client_id,
}: {
    client_id: number
}) => {
    return axios({
        method: 'GET',
        url: `/api/ext/client/${client_id}`,
    })
        .then(res => res.data?.data)
        .catch(error => {
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

export const updateExternalClient = async ({ client_id, data }) => {
    return axios({
        method: 'PUT',
        url: `/api/ext/client/${client_id}`,
        data,
    })
        .then(res => {
            return res.data
        })
        .catch(error => {
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

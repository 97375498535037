import React, { FC, Fragment } from 'react'

import { FormikProps } from 'formik'
import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    GridItem,
    HStack,
    Text,
    Input,
} from '@chakra-ui/react'
import { colors } from '@/utils/const'

interface Props {
    formik: FormikProps<FormikValues>
    isEmailDuplicated: boolean
}

interface FormikValues {
    licenses: { license: string; email: string }[]
}

export const AddLicence: FC<Props> = ({ formik, isEmailDuplicated }) => {
    const addRepresentative = () => {
        let newLicense = formik.values.licenses
        newLicense.push({
            license: '',
            email: '',
        })
        formik.setFieldValue('licenses', newLicense)
    }

    const removeRepresentative = () => {
        let newLicense = formik.values.licenses
        newLicense.pop()
        formik.setFieldValue('licenses', newLicense)
    }

    return (
        <Box mb={4} color="black" fontSize={14} fontWeight={500}>
            {formik.values.licenses.length > 0 && (
                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                    {formik.values.licenses?.map((item, index) => (
                        <Fragment key={index}>
                            <GridItem>
                                <FormControl
                                    isInvalid={
                                        Array.isArray(formik.errors.licenses) &&
                                        formik.errors?.licenses[index] // @ts-ignore
                                            ?.license &&
                                        Array.isArray(
                                            formik.touched.licenses,
                                        ) &&
                                        formik.touched.licenses[index]?.license
                                    }>
                                    <FormLabel fontSize={13} fontWeight={700}>
                                        Nº licencia {index + 2}
                                    </FormLabel>
                                    <Input
                                        id={`licenses[${index}].license`}
                                        placeholder={`Nº licencia ${index + 2}`}
                                        rounded="4px"
                                        size="xs"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={
                                            formik.values.licenses[index]
                                                .license
                                        }
                                    />
                                    {Array.isArray(formik.errors.licenses) && (
                                        <FormErrorMessage>
                                            {
                                                formik.errors.licenses[
                                                    index /* @ts-ignore */
                                                ]?.license
                                            }
                                        </FormErrorMessage>
                                    )}
                                </FormControl>
                            </GridItem>

                            <GridItem key={index}>
                                <FormControl
                                    isInvalid={
                                        Array.isArray(formik.errors.licenses) &&
                                        // @ts-ignore
                                        formik.errors?.licenses[index]?.email &&
                                        Array.isArray(
                                            formik.touched.licenses,
                                        ) &&
                                        formik.touched.licenses[index]?.email
                                    }>
                                    <FormLabel fontSize={13} fontWeight={700}>
                                        Email de licencia {index + 2}
                                    </FormLabel>
                                    <Input
                                        id={`licenses[${index}].email`}
                                        placeholder={`Email de licencia ${
                                            index + 2
                                        }`}
                                        rounded="4px"
                                        size="xs"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={
                                            formik.values.licenses[index].email
                                        }
                                    />
                                    {Array.isArray(formik.errors.licenses) && (
                                        <FormErrorMessage>
                                            {
                                                formik.errors.licenses[
                                                    index /* @ts-ignore */
                                                ]?.email
                                            }
                                        </FormErrorMessage>
                                    )}
                                </FormControl>
                            </GridItem>
                        </Fragment>
                    ))}
                </Grid>
            )}

            {isEmailDuplicated && (
                <Text
                    fontSize={12}
                    color={colors['danger-high']}
                    fontWeight={700}
                    my={4}>
                    Existen correos duplicados, debe ser único para cada
                    licencia.
                </Text>
            )}

            <HStack justify="center" my={4} spacing={6}>
                <Button
                    color={colors.secondary}
                    fontSize={13}
                    fontWeight={700}
                    variant="link"
                    onClick={addRepresentative}
                    _active={{}}
                    _hover={{}}>
                    + Agregar otra licencia
                </Button>

                {formik.values.licenses.length > 0 && (
                    <Button
                        color={colors['danger-high']}
                        fontSize={13}
                        fontWeight={700}
                        variant="link"
                        onClick={removeRepresentative}
                        _active={{}}
                        _hover={{}}>
                        - Eliminar una licencia
                    </Button>
                )}
            </HStack>
        </Box>
    )
}

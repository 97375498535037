export const UNIQUE_EMAILS = 'Los correos deben ser únicos'

export const JUSTIFICATION_PHASES_OPTIONS = {
    justification_1_status: {
        label: 'Justificación 1',
        value: 'justification_1_status',
    },
    justification_2_status: {
        label: 'Justificación 2',
        value: 'justification_2_status',
    },
}

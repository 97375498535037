export const HAS_PRINTER_OPTIONS = {
    yes: {
        label: 'Sí',
        value: 'yes',
    },
    no: {
        label: 'No',
        value: 'no',
    },
}

export const HAS_LAPTOP_OPTIONS = {
    yes: {
        label: 'Sí',
        value: 'yes',
    },
    no: {
        label: 'No',
        value: 'no',
    },
}

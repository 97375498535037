export const VOUCHER_STATUS = {
    initial: {
        name: 'initial',
        label: 'Inicial',
    },
    negotiation: {
        name: 'negotiation',
        label: 'Negociaci\u00f3n',
    },
    collection_process: {
        name: 'collection_process',
        label: 'Proceso de cobro',
    },
    implantation: {
        name: 'implantation',
        label: 'Implantaci\u00f3n',
    },
    closed: {
        name: 'closed',
        label: 'Finalizado',
    },
    lost: {
        name: 'lost',
        label: 'Perdido',
    },
    pending_approved_re_requested: {
        name: 'pending_approved_re_requested',
        label: 'Pendiente aprobaci\u00f3n Resolicitado',
    },
    approved_re_requested: {
        name: 'approved_re_requested',
        label: 'Aprobado Resolicitado Red.es',
    },
    re_requested: {
        name: 're_requested',
        label: 'Resolicitado',
    },
}

export const CAMPAIGN_STATUS = {
    active: {
        name: 'active',
        label: 'Activa',
    },
    inactive: {
        name: 'inactive',
        label: 'Inactiva',
    },
    deleted: {
        name: 'deleted',
        label: 'Borrada',
    },
}

export const CAMPAIGN_TYPES = {
    informative: {
        value: 'informative',
        label: 'Informativa',
    },
    opportunities: {
        value: 'opportunities',
        label: 'Oportunidades',
    },
}

export const POSTPONE_OPTIONS = {
    yes: {
        label: 'Si',
        value: 'yes',
    },
    no: {
        label: 'No',
        value: 'no',
    },
}

export const CAMPAIGN_FROM_MESSAGE_MAX_LENGTH = 11
export const CAMPAIGN_URL_MAX_LENGTH = 11
export const CAMPAIGN_GOOGLE_SHEETS_MAX_LENGTH = 44
export const CAMPAIGN_BODY_MESSAGE_MAX_LENGTH = 151 - CAMPAIGN_URL_MAX_LENGTH

export const MONTHS_OPTIONS = {
    yes: {
        label: 'Si',
        value: 'yes',
    },
    no: {
        label: 'No',
        value: 'no',
    },
}

export const SENDER_OPTIONS = {
    sms: 'sms.to',
    ws: 'WhatsApp',
}
